@font-face {
  font-family: big-john;
  src: url("../fonts/bigJohnSlimJoe/bigJohn.otf");
}

html, body {
  overflow: hidden;
}

body {
  position: relative;
  margin: 0px;
  overflow: hidden;
  top: 0px;
  bottom: 0px;
  width: 100%;
}

.splash {
  text-align: center;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.coming-soon, .pageText {
  margin: 0; 
  height: 100%; 
  overflow: hidden;
}

.splash-logo, .Nec-logo, .Fen-logo, .strapline-logo {
  width: 35%;
  height: 35%;
  pointer-events: none;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.coming-soon-text {
  width: 60%;
  height: 60%;
  pointer-events: none;
  position: relative;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.splash-header, .pageText {
  background-color: #000000;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
  padding-top: 0%;
  padding-bottom: 0%;
  overflow: hidden;
}
  
.coming-soon, .strapline, .Nec-logo, .Fen-logo, .coming-soon-text, .white, .orange{
  display: flex;
  justify-content: center;
}

.splash-logo, .Nec-logo, .Fen-logo, .strapline-logo, .coming-soon-text, .white, .orange{
  text-align: center;
  align-items: center;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
}

.icons {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.instagram-icon, .facebook-icon {
  height: 30px;
  width: 30px;
  position: relative;
  padding-bottom: 47px;
  padding-top: 45%;
  display: flex;
  flex-direction: column;
  transition: transform .2s;
}

.linkedin-icon {
  height: 30px;
  width: 30px;
  position: relative;
  padding-bottom: 47px;
  padding-top: 20%;
  padding-right: 17px;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  transition: transform .2s;
}

.linkedin-icon:hover, .facebook-icon:hover, .instagram-icon:hover {
  transform: scale(1.4);
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 10vh;
  background-color: #000000;
  text-align: center;
  font-size: 170%;
  overflow: auto;
}

.splash-header {
  position: fixed;
  overflow: auto;
}

.splash-logo{
  animation:8s ease 0s normal forwards 1 fadein;
  -webkit-animation:8s ease 0s normal forwards 1 fadein;
  opacity:1
}
    
@keyframes fadein{
  0%{opacity:0}
  10%{opacity:0}
  100%{opacity:1}
}

@-webkit-keyframes fadein{
  0%{opacity:0}
  10%{opacity:0}
  100%{opacity:1}
}
  
.strapline-logo{
  animation:6.5s ease 0s normal forwards 1 fadeinstrap;
  -webkit-animation:6.5s ease 0s normal forwards 1 fadeinstrap;
  opacity:1
}
    
@keyframes fadeinstrap{
  0%{opacity:0}
  30%{opacity:0}
  100%{opacity:1}
}
    
@-webkit-keyframes fadeinstrap{
  0%{opacity:0}
  30%{opacity:0}
  100%{opacity:1}
}
  
.Fen-logo {
  position: absolute;
  -webkit-animation: linear;
  -webkit-animation-name: leftToRight;
  -webkit-animation-duration: 4s;
}
  
@keyframes leftToRight{
  0% {
    padding-right: 100%;
    opacity: 0;
  }
  50% {
    padding-right: 100%;
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    padding-right: 0%;
    opacity: 1;   
  }
}
  
@-webkit-keyframes leftToRight {
  0% {
    padding-right: 100;
    opacity: 0;
  }
  50% {
    padding-right: 100%;
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    padding-right: 0%;
    opacity: 1;  
  }
}
  
.Nec-logo {
  position: absolute;
  -webkit-animation: linear;
  -webkit-animation-name: rightToLeft;
  -webkit-animation-duration: 4s;
}
    
@keyframes rightToLeft{
  0% {
    padding-left: 100%;
    opacity: 0;
  }
  50% {
    padding-left: 100%;
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    padding-left: 0%;
    opacity: 1;   
  }
}

@-webkit-keyframes rightToLeft {
  0% {
    padding-left: 100;
    opacity: 0;
  }
  50% {
    padding-left: 100%;
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    padding-left: 0%;
    opacity: 1;  
  }
}
    
.coming-soon-text, .icons {
  animation:9.5s ease 0s normal forwards 1 fadeinstrap;
  -webkit-animation:9.5s ease 0s normal forwards 1 fadeinstrap;
  opacity:1
}
  
@keyframes fadeinstrap{
    0%{opacity:0}
    68%{opacity:0}
    100%{opacity:1}
}

@-webkit-keyframes fadeinstrap{
    0%{opacity:0}
    68%{opacity:0}
    100%{opacity:1}
}
    
/* Mobile Specific CSS Below */

@media all
and (min-width:280px)
and (max-width: 480px) {
  html, body {
    overflow-x: hidden;
  }

  .splash-logo, .Nec-logo, .Fen-logo, .strapline-logo, .coming-soon-text {
    width: 60%;
    height: auto;
    pointer-events: none;
    text-align: center;
    align-items: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
}

/*
@media all
and (min-width:280px)
and (max-width: 480px) {
  html, body {
    overflow-x: hidden;
  }
  
  body {
    position: relative;
    margin: 0px;
    overflow: hidden;
    top: 0px;
    bottom: 0px;
    width: 100%;
}

  .splash {
    text-align: center;
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

  .splash-logo, .Nec-logo, .Fen-logo, .strapline-logo, .coming-soon-text {
    width: 50%;
    height: auto;
    pointer-events: none;
    text-align: center;
    align-items: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding-top: 40%;
  }

  .coming-soon {
    margin: 0; 
    height: 100%; 
    overflow: hidden;
  }
}

/* Tablet Specific CSS Below */

/* @media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1366px)
and (min-device-height : 768px)
and (max-device-height : 1366px) {
  html, body {
    overflow-x: hidden;
  }
  body {
    position: relative;
  }

  .splash {
    text-align: center;
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

  .splash-logo, .Nec-logo, .Fen-logo, .strapline-logo {
    width: 50%;
    height: auto;
    pointer-events: none;
    position: relative;
    text-align: center;
    align-items: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding-top: 10%;
  }

  .coming-soon {
    margin: 0; 
    height: 100%; 
    overflow: hidden;
  }
}
*/
